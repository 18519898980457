/* ==========================Register Page Css================================================ */

body {
  background-color: #faf8fa;
}

.be-post-title {
  color: #fff;
}

h3 {
  color: #fff;
}

/* .btn.color-1 {background-image: linear-gradient(to right, #171716 0%, #ffe429 51%, #110e08 100%);} */


header {
  height: 70px;
  width: 100%;
  color: #fff;
  box-shadow: 0 0 15px rgb(240 233 233 / 79%);
  font-size: 14px;
  background: #ae10bd !important;
  position: fixed;
  top: 0;
  line-height: 0;
  z-index: 1000;
}

/* ul.d-flex.flex-wrap.social-link.social-link-banner.pl-0 {
    margin-top: 35px !important;
    background-color: red;
} */

.row.text-white h6 {
  font-size: 20px;
  line-height: 10px;
  margin-top: 20px;
}



.submit-block {
  cursor: pointer;
  height: 45px;
  width: 45px;
  /*background-image: linear-gradient(37deg, #171716 38%, #ffe429 104%)!important;*/
  color: #fff;
  border: 1px solid #fff;
}

h3.one {
  position: relative;
}

.submit-block i {
  color: #fff !important;
}

/* footer {
  background: #000!important;
  } */
.animate-new-talent {

  animation: effect 2s linear infinite;
  color: transparent;

}

@keyframes effect {
  0% {
    color: blue
  }

  100% {
    color: white;
  }
}

.toggle-password {
  position: absolute;
  right: 7px;
  top: 20px;
  color: #fff;
}


/* ===================Login Page CSS=========================================================== */

body {
  background-color: #fff;
}

.be-post-title {
  color: #fff;
}

h3 {
  color: #fff;
}

.btn.color-1 {
  /* background: #F9D77E;*/
  border: 1px solid #Ffff;
}

.btn.color-1 .fa {
  color: #fff;
}

/*
    .btn.color-1 {
    background-image: linear-gradient( -72deg, #dac159, #ffffff 16%, #dac159 21%, #c9c9c9 24%, #dac159 27%, #dac159 36%, #d8d7d7 45%, #ffffff 60%, #dac159 72%, #ffffff 80%, #dac159 84%, #452100);
    box-shadow: 2px 2px 0.5em rgb(122 98 0 / 55%), inset 1px 1px 0 rgb(255 255 255 / 90%), inset -1px -1px 0 rgb(0 0 0 / 34%)!important;
    border: 1px solid #0d58c8!important;
    color: #000!important;
    background-size: 200% auto;
    transition: all 0.5s ease 0s;
    outline: none;
    }
    .btn.color-1:hover {
    background-image: linear-gradient( -72deg, #dac159, #ffffff 16%, #dac159 21%, #c9c9c9 24%, #dac159 27%, #dac159 36%, #d8d7d7 45%, #ffffff 60%, #dac159 72%, #ffffff 80%, #dac159 84%, #452100);
    box-shadow: 2px 2px 0.5em rgb(122 98 0 / 55%), inset 1px 1px 0 rgb(255 255 255 / 90%), inset -1px -1px 0 rgb(0 0 0 / 34%)!important;
    color: #fff!important;
    border: 1px solid #dac159!important;
    }*/
header {
  height: 70px;
  width: 100%;
  color: #fff;
  box-shadow: 0 0 15px rgb(240 233 233 / 79%);
  font-size: 14px;
  background: #ae10bd !important;
  position: fixed;
  top: 0;
  line-height: 0;
  z-index: 1000;
}

.submit-block {
  cursor: pointer;
  height: 45px;
  width: 45px;
  /*background-image: linear-gradient(37deg, #171716 38%, #ffe429 104%)!important;*/
  color: #fff;
  border: 1px solid #fff;
}

.submit-block i {
  color: #fff !important;
}

/* footer {
    background: #000!important;
    } */
.toggle-password {
  position: absolute;
  right: 7px;
  top: 20px;
  color: #fff;
}

.animate-new-talent {

  animation: effect 2s linear infinite;
  color: transparent;

}

@keyframes effect {
  0% {
    color: blue
  }

  100% {
    color: white;
  }
}

/* ===================    Viewall CSS ================================================= */

body {
  background-color: #eee;
}

.be-post-title {
  color: #fff;
}

h3 {
  color: #fff;
}

/* .btn.color-1 {background-image: linear-gradient(to right, #171716 0%, #ffe429 51%, #110e08 100%);}
      .btn.color-1 {
      /* margin: 10px; */
/* padding: 15px 45px; */
/* text-align: center; */
/* text-transform: uppercase; */
/* transition: 0.5s; */
/* background-size: 200% auto; */
/* color: white;
      box-shadow: 0 0 20px #e3c87e;
      border-radius: 10px; */
/* display: block; */
/* } */
/* .btn.color-1:hover {
      background-position: right center;  change the direction of the change here 
      color: #fff;
      border:1px solid #dfc172!important;
      text-decoration: none;
      }  */

.btn.color-1 {
  background: #6f42c1;

  border: 1px solid #6f42c1;
}

.btn.color-1 .fa {
  color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: linear-gradient(90deg, #ffffff 0%, #623a80 69%, #4B1267 100%);
  ;
  /* border-color: #ff7cf6; */
}

/*
      .btn.color-1 {
      background-image: linear-gradient( -72deg, #dac159, #ffffff 16%, #dac159 21%, #c9c9c9 24%, #dac159 27%, #dac159 36%, #d8d7d7 45%, #ffffff 60%, #dac159 72%, #ffffff 80%, #dac159 84%, #452100);
      box-shadow: 2px 2px 0.5em rgb(122 98 0 / 55%), inset 1px 1px 0 rgb(255 255 255 / 90%), inset -1px -1px 0 rgb(0 0 0 / 34%)!important;
      border: 1px solid #0d58c8!important;
      color: #000!important;
      background-size: 200% auto;
      transition: all 0.5s ease 0s;
      outline: none;
      }
      .btn.color-1:hover {
      background-image: linear-gradient( -72deg, #dac159, #ffffff 16%, #dac159 21%, #c9c9c9 24%, #dac159 27%, #dac159 36%, #d8d7d7 45%, #ffffff 60%, #dac159 72%, #ffffff 80%, #dac159 84%, #452100);
      box-shadow: 2px 2px 0.5em rgb(122 98 0 / 55%), inset 1px 1px 0 rgb(255 255 255 / 90%), inset -1px -1px 0 rgb(0 0 0 / 34%)!important;
      color: #fff!important;
      border: 1px solid #dac159!important;
      }*/
header {
  height: 80px;
  width: 100%;
  color: #fff;
  box-shadow: 0 0 15px rgb(0 0 0 / 0%);
  font-size: 14px;
  background: transparent !important;
  position: fixed;
  top: 0;
  line-height: 0;
  z-index: 1000;
  background-repeat: no-repeat;
  background-position: center 0;
  -webkit-background-size: cover;
  background-size: cover;
}

.submit-block {
  cursor: pointer;
  height: 45px;
  width: 45px;
  /*background-image: linear-gradient(37deg, #171716 38%, #ffe429 104%)!important;*/
  color: #fff;
  border: 1px solid #fff;
}

.submit-block i {
  color: #fff !important;
}

/*       
      footer {
      background: #4B1267 !important;
      } */



.animate-new-talent {

  animation: effect 2s linear infinite;
  color: transparent;

}



.transactionhistory table tr th,
.transactionhistory table tr td {
  color: white;
}

@media (max-width: 425px) {
  img.brand-logo {
    width: 200px;
    left: 0;
  }

  ul.dropdown-menu.show {
    border-bottom: 1px solid #555;
    background: #ffffff00;
    position: absolute;
    will-change: transform;
    top: 5px !important;
    right: 20px !important;
    /* left: -10px!important; */
    transform: translate3d(0px, 22px, 0px);
    border: 0px !important;
  }

  /* ==========tablr css------------- */
  #as-react-datatable-container select {
    /* padding: 20px; */
    width: 65px !important;
    height: 32px;
  }

  #as-react-datatable-table-foot .asrt-pagination {
    margin-top: 9px;
    margin-bottom: 10px;
  }

  /* ==========tablr css------------- */

  .carousel-inner>.item>a>img,
  .carousel-inner>.item>img {
    display: block;
    max-width: 100%;
    height: 245px !important;
    /* object-fit: cover; */
  }

  .search_ul .mobile-font {
    font-size: 18px !important;
  }

  /* header css */
  .left-feild #scrollspy.affix {
    position: relative !important;
    top: 0 !important;
  }

  .pad-spc {
    padding: 0px !important;
  }

  .creative_filds_block .nav {
    width: 100%;
  }

  .new-collection {
    padding-top: 8px;
    /* padding-right: 7px!important; */
    cursor: pointer;
    font-size: 12px;
  }

  .tab-wrapper.style-1 .nav-tab-item span,
  .tab-wrapper.style-2 .nav-tab-item span,
  .tab-wrapper.style-3 .nav-tab-item span {
    display: block;
    font-size: 12px;
    line-height: 13px;
    color: inherit;
    padding: 11px;
  }

  .link-feature a {
    padding-right: 0;
    font-size: 16px;
    color: #fff;
  }
}

@media (max-width: 320px) {}

.pad-spc {
  padding: 3rem;
}

.image-auth {
  height: 115px;
  width: 115px;
}

#add-collection .close {
  margin-top: -25px;
  font-size: 27px;
}

.newsletter {
  /* background:linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("https://themes.getmotopress.com/emmet/wp-content/themes/emmet/images/main-bg.jpg"); */
  /*background-image: url(https://themes.getmotopress.com/emmet/wp-content/themes/emmet/images/main-bg.jpg);*/
  background-position: center top;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.newsletter:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
}

.newsletter-form {
  padding: 80px 0px;
}

.subscribe-form input[type="email"] {
  font-size: 16px;
  width: 100%;
  height: 45px;
  border: none;
  background: transparent;
  color: #fff;
  padding: 15px;
  /* border-top-left-radius: 20px!important;
border-bottom-left-radius: 20px!important; */
  padding-right: 46px;
  /* border-bottom-right-radius: 20px!important;
border-top-right-radius: 20px!important; */
  /* border-radius: 4px; */
  border: 1px solid #fff;
}

.submit-block span {
  position: absolute;
  top: 0px;
  right: 50%;
  font-size: 22px;
  /* text-transform: uppercase; */
  line-height: 45px;
  color: #fff;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.price-data {
  margin-top: -28px;
}


.accordion.style-2 .acc-title {
  background: #ffb900 none repeat scroll 0 0;
  border: 1px solid transparent;
  border-radius: 30px;
  color: #fff;
  /*  background-color: #2234ae;*/
  background-image: linear-gradient(315deg, #b95ce2 0%, #2234ae 74%);

  display: block;
  font-size: 18px;
  /*font-weight: 600;*/
  padding: 12px 20px 12px 50px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.accordion.style-2 .acc-panel {
  margin-bottom: 26px;
  border: none !important;
}

.accordion.style-2 .acc-icon {
  position: absolute;
  top: -7px;
  left: -20px;
  border-radius: 50%;
  bottom: -4px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 58%);
  width: 55px;
  height: 55px;
  /*background: #ffb900;*/
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #b95ce2 0%, #2234ae 74%);

}

.accordion.style-2 .acc-body {
  display: none;
  background: transparent;
  color: #fff;
  border-left: 1px dashed #fff;
  padding-left: 22px;
  margin-left: 9px;
  margin-top: 15px;
  font-size: 13px;
  /* border-left: 1px dashed #fff; */
  /* padding-left: 19px; */
  line-height: 22px;
  padding: 15px;
}

/*.accordion.style-2 .acc-body p{
  border-left: 1px dashed #fff;
    padding-left: 22px;
}*/
.accordion.style-2 .acc-title.active {
  /*background: #ffb900;*/
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #b95ce2 0%, #2234ae 74%);

  border-bottom: 1px solid #010101;
}

.accordion.style-2 .acc-title.active .acc-icon {
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #b95ce2 0%, #2234ae 74%);
}

@media only screen and (max-width: 1024px) {
  .input-search i {
    position: absolute;
    top: 62%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    color: #000;
    font-size: 22px;
    opacity: 0.3;
  }

  .as-react-table {
    overflow-y: auto;
  }

  .search_ul li a span {
    font-size: 14px;
  }
}

.accordion.style-2 .acc-icon:before {

  color: #fff;
}

.accordion.style-2 .acc-title.active .acc-icon:before {
  color: #fff;
}

.search_ul {
  /* display: none; */
  max-height: 400px;
  overflow-x: scroll;
  z-index: 1;
  position: absolute;
  width: 400px;
  left: -37px;
  background: #fff;
  /* border: 2px solid #ddd; */
  padding-left: 10px;
}

.indent {
  margin-left: 36px;
}

.indent li {
  line-height: 1.4em;
  margin-bottom: 14px;
}

.privacy_policy p {
  margin-bottom: 2rem;
  font-size: 15px;
  line-height: 1.9em;
  color: inherit;
  opacity: 0.9;
}

ul.link-list {
  list-style: none;
}

.profile_picture.x-small {
  height: 36px;
  width: 36px;
  border: 0;
}

.profile_picture {
  border-radius: 50%;
}

.profile_picture.x-small {
  height: 36px;
  width: 36px;
  border: 2px solid #fff;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.d-inline-block {
  display: inline-block !important;
}

.table-cart-detail .table-responsive {
  border: none;
}

.table-cart-detail table tr td,
.table-cart-detail table tr th {
  border: none !important;
}

.table-cart-detail table tr {
  border-top: 1px solid #ffffff61;
  white-space: nowrap;
}

/* //=============================  Faq css */

.head-bg {
  background: #000;
  position: relative;
  padding: 10px;
  margin-bottom: 50px;
}

.head-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://images03.nicepage.com/a1389d7bc73adea1e1c1fb7e/06790d51f44657ba884f1148/pexels-photo-6672227.jpeg?version=);
  height: 100%;
  width: 100%;
  background-size: cover;
  opacity: 0.2;
  z-index: 1;
  background-position: center center;
}

.cart-detail-container .tab-wrapper.style-1 .nav-tab-item.active,
.cart-detail-container .tab-wrapper.style-2 .nav-tab-item.active,
.cart-detail-container .tab-wrapper.style-3 .nav-tab-item.active {
  color: inherit;
  background: linear-gradient(315deg, #000 0%, #2234ae 74%);
  border: 1px solid #fff;
  /* margin-top: 20px !important; */
  margin-top: 30px !important;
}

.head-bg-content {
  height: 263px;
  text-align: center;
  position: relative;
  z-index: 200;
  display: table-cell;
  vertical-align: middle;
  width: 5000px;
}

.cart-detail-container .tab-nav-wrapper .nav-tab-item {
  background: #000;
  border: 1px solid;
  height: 70px;
}

.cart-detail-container .tab-nav-wrapper .nav-tab-item span {
  /* width: 20%; */
  padding: 19px 0px;
  text-align: center;
  line-height: 17px;
}

.cart-detail-container .tab-nav-wrapper .nav-tab-item span {
  /* width: 20%; */
  padding: 23px 0px;
  text-align: center;
}

.cart-detail-container ul {
  list-style: none;
}

.animate-new-talent {

  animation: effect 2s linear infinite;
  color: transparent;

}

@keyframes effect {
  0% {
    color: blue
  }

  100% {
    color: white;
  }
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%);
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.explore .nav-tab-item span {
  color: #fff !important;
}

.error-asterick {
  color: red;
}

.itemTable .table-striped>tbody>tr:nth-of-type(odd) {
  background: transparent;
}

.itemTable .table-bordered {
  border: 1px solid transparent;
}

.itemTable .input-group-prepend {
  background: transparent !important;
}

.itemTable .form-control {
  background-color: transparent;
  color: #fff;
}

.itemTable .form-control option {
  background-color: transparent;
  color: #000;
}

.itemTable .input-group-text {
  color: #fff;
}

.itemTable .pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #fff;
  cursor: not-allowed;
  background-color: transparent;
  border-color: #ddd;
}

.yourPurchases .nav-tab-item span {
  color: inherit !important;
}

.validat {
  left: 8px;
  font-size: 30px;
}

.share-dropdown {
  border: 1px solid #fff !important;
  /* line-height: 18px; */
  padding: 8px 3px !important;
}

.share-dropdown:after {
  display: none !important;
}

.share-dropdown .be-dropdown-content {
  position: relative;
  left: 7px;
}

.share-dropdown .a-list a {
  display: block;
  text-align: left;
  font-size: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #fff !important;
  border-bottom: 1px solid #b4b7c1 !important;
}

.share-dropdown .a-list {
  width: auto;
  min-width: 150px;
  border: 1px solid #fff !important;
  background: transparent !important;
  border: none;
}

.user-dropprofile .a-list {
  width: 100%;
}

.user-dropprofile .be-drop-down.login-user-down {
  background: transparent;
}

.sucessmsg {
  width: fit-content;
  color: #155724;
  background-color: #d4edda;
  opacity: 1;
}

.warningMsg {
  width: fit-content;
  color: #856404;
  background-color: #fff3cd;
  opacity: 1;
}

/*features creation */
.side-scroll {
  overflow-x: scroll;
  position: relative;
  padding-right: 0;
  /* float: right; */
  display: flex;
  padding-left: 3em;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.featured_creation_item {
  color: #303030;
  margin-bottom: 10px;
  margin-right: 27px;
  width: 87px;
  display: inline-flex;
}

/* ul.d-flex.flex-wrap.social-link.social-link-banner.pl-0 li {
    margin-top: 35px !important;
    padding: 35px;
} */

#icons {
  margin-top: 35px !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.featured-item-widget.compact .featured_item {
  width: 90px;
}

.featured_creation_item .creation_content {
  width: 100px;
  /*overflow: hidden;*/
}

.grid_item_container {
  position: relative;
}

.item-content.compact {
  margin-bottom: 7px;
}

a.muted {
  color: #303030;
  text-decoration: none;
}

.featured_item img.item_image {
  border-radius: 50%;
}

.featured_item img.item_image {
  width: 100%;
}

.featured_item .creator_profile img.profile_picture {
  width: 30px;
  height: 30px;
  z-index: 1;
  position: relative;
  border: 2px solid #fff;
}

.profile_picture {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #ebe2e9;
}

.featured_item .creator_profile {
  margin-top: -2.4em;
  margin-left: 5.2em;
}

.featured_item_wrp {
  width: 105px !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.featured-item-widget.compact .featured_item_wrp {
  margin-right: 8px;
}

/* width */
.side-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.side-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.side-scroll::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.side-scroll::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.author-post img {
  border-radius: 50%;
  display: inline;
  width: 67px;
  bottom: 104px;
  position: absolute;
  border: 1px solid #fff;
  /* object-fit: cover; */
  height: 67px;
  margin-right: 5px;
}

.profile-banner .be-post .be-img-block:after {
  border: none;
}

.comments-title {
  color: #FFF;
}

.be-comment-content a {
  color: #FFF;
}

.poa_icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.h6 {
  font-size: 19px;
  font-weight: 300;
  color: #fff;
}

.h7 {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.offer-button {
  font-size: 14px !important;
}

a.primary,
a.primary:visited {
  font-weight: 400;
  color: #337ab7 !important;
}

.feature-creator .nav-tab-item span {
  color: #fff !important;
}

.swal2-html-container {
  font-size: 15px !important;
}

.addNfts-label .form-label {
  color: #fff;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(https://c4.wallpaperflare.com/wallpaper/937/959/751/background-black-dark-black-curved-bloom-abstract-fantasy-hd-art-wallpaper-preview.jpg);
  margin-bottom: 0;
}

.login_edit_detail .list-group-item {
  position: relative;
  display: block;
  padding: 13px 6px;
}

.login_edit_detail .list-group-item {
  background: transparent;
  border: 1px solid #bfc9d4;
}

.activity .new-control {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 1.5rem;
  margin-right: 1rem;
  font-weight: 100;
  font-size: 14px;
  color: #fff;
}

.d-block {
  display: block !important;
}

.account-verify {
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  a#connect_btn_top {
    margin: 10px 10px !important;
  }

  a#copy_wallet {
    margin: 0px 10px !important;
    position: relative;
    right: 0px;
  }

  .carousel-caption {
    position: absolute;
    right: 0%;
    bottom: 23px;
    left: 0%;
    z-index: 10;
    padding-top: 9px;
    padding-bottom: 9px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  .table_filter {
    margin-top: 10px;
  }

  .carousel-caption h3 {
    font-size: 14px;
  }

  .carousel-caption p {
    font-size: 11px;
  }

  .carousel-indicators {
    position: absolute;
    bottom: 0px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
  }
}

@media only screen and (max-width: 320px) {
  .carousel-caption {
    position: absolute;
    right: 0%;
    bottom: 23px;
    left: 0%;
    z-index: 10;
    padding-top: 9px;
    padding-bottom: 9px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  .submit-block {
    width: 87px !important;
  }

  .submit-block span {
    font-size: 16px;
  }

  .subscribe-form input[type="email"] {
    font-size: 15px;

  }

  .carousel-indicators {
    position: absolute;
    bottom: 0px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
  }

  .carousel-caption h3 {
    font-size: 11px;
  }

  .carousel-caption p {
    font-size: 10px;
  }
}

/* ========================== */
.new-collection {
  text-transform: inherit;
  display: inline-block;
  float: left;
}

.link-copy {
  width: 32px;
  background: #a39d9dc9;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.addnftdate .react-date-picker__wrapper {
  border: transparent;
}

.pencilcollection {
  font-size: 14px;
  color: grey;
  cursor: pointer;
}

/* ============================== */
/* .carousel-caption p {
  font-size: 18px;
} */
@media screen and (min-width: 768px) {
  .carousel-caption {
    right: 20%;
    padding-left: 20px;
    padding-right: 20px;
    left: 20%;
    padding-bottom: 30px;
  }
}

.market_recent_activity table tr td {
  white-space: nowrap;
}

.react-date-picker__button {
  padding: 0px 6px !important;
}

.scrollmenu {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
}

.scrollmenu .nav-tab-item {
  display: inline-block !important;
  float: left !important;
  /* width: 11750px; */
}

.scrollmenu div {
  display: inline-block !Important;
}

/* width */
.scrollmenu::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

/* Track */
.scrollmenu::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
  background: #0d58c8;
}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
  background: #0d58c8;
}

.textOwn {
  text-align: initial;
}

input.no-autofill-bkg:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #999;
  color: #fff;


}

.preview_image_data {
  height: 150px;
  width: 100%;
}

.audioNft audio.react-audio-player {
  width: 100%;
  margin-top: 30px;
}

.video-react .video-react-big-play-button {
  top: 40% !important;
  left: 40% !important;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.be-img-block audio {
  position: absolute;
  bottom: 0;
  left: 29px;
  right: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* .unc-btn {
  background: #ae10bd;
  width: 100%;
  background-image: linear-gradient( 
90deg
, #ffffff 0%, #623a80 69%, #4B1267 100%);
  margin: 0px 0px 0px 10px;
  max-width: 192px;
  font-weight: 700;
  height: 48px;
  line-height: 48px;
  padding: 0 10px 0 10px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  -webkit-border-radius: 0;
  border-radius: 0px;
  -webkit-box-shadow: 0 6px 15px 2px rgb(38 70 83 / 20%);
  box-shadow: 0 6px 15px 2px rgb(38 70 83 / 20%);
  font-weight: 400;
}

.unc-btn:hover {
  background-image: linear-gradient( 
30deg
, #ffffff 0%, #623a80 69%, #4B1267 100%);
  color: #fff;
  text-decoration: none;
} */

/* .banner-container {
  background-image: url(../images/bgr-header.png); 
   background-position: center bottom;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 299px 0 0px 0;
} */

.sm-data-box .data-wrap-left,
.sm-data-box .data-wrap-right {
  padding-top: 19px;
  padding-bottom: 19px;
  min-height: 99px;
}

.weight-500 {
  font-weight: 500 !important;
}

.sm-data-box .counter {
  font-size: 24px;
}

.uppercase-font {
  text-transform: uppercase !important;
}

.colm-lg-3 {
  width: 28%;
}

/* ==========================Buy Now=================================================== */

div#wrapper {
  /* background-image: url(assets/images/bgr-happenning.png); */
  background-image: url(https://us.123rf.com/450wm/mehaniq/mehaniq1801/mehaniq180100770/93510602-abstract-violet-blurred-surface-soft-background-image-multicolored-space.jpg?ver=6);
  /* background-position: center bottom; */
  background-repeat: no-repeat;
  background-size: cover;
}

.form-container {
  width: 100%;
  max-width: 450px;
  margin: 100px auto;
  margin-bottom: 0;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=number],
textarea {
  width: 100%;
  margin: 0px auto;
  border: 1px solid white;
  box-shadow: 0 0 1px 1px white;
}

input#step2 {
  display: none;
}

textarea {
  min-height: 150px;
}

.panel {
  background: rgba(0, 0, 0, .2);
}

.panel-heading {
  margin-bottom: 20px;
}

.btn-group {
  width: 90%;
  margin: 0 auto 10px auto;
}

.back {
  float: left;
}

.continue {
  float: right;
}


/* Multi-Part CSS */
input[type=checkbox] {
  position: absolute;
  /* Position the checkbox in upper left corner */

  opacity: 0;
}

/* Styling Parts */
#part1,
#part2,
#part3 {
  z-index: 2;
  display: block;
  height: auto;
  opacity: 1;
  /*transition: opacity 1s ease-in-out;*/
}

/* Hidden Parts */
#part2,
#part3 {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

/* Show Step 2 & Hide Step 1 */
#step2:checked~#part2 {
  opacity: 1;
  height: auto;
  box-shadow: 0 2px 25px rgb(234 231 231 / 51%);
}

#step2:checked~#part1 {
  opacity: 0;
  height: 0;
  display: none;
}

/* Show Step 3  & Hide Step 2 */
#step3:checked~#part3 {
  opacity: 1;
  height: auto;
}

#step3:checked~#part2 {
  opacity: 0;
  width: 0;
  height: 0;
}

/* .panel.panel-primary {
  background: linear-gradient(
45deg
,#6c019e,#b10093);
  padding: 20px;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 2px 25px #00000026;
} */
.panel.panel-primary {
  background: transparent;
  padding: 20px;
  border-radius: 5px;
  min-height: 313px;
  border: 0;
  box-shadow: 0px 2px 25px #eae7e782;
}

select#cars {
  padding: 6px;
  border-radius: 5px;
  margin: 15px 0px;
}

.amount p,
.Fee p {
  font-family: Roboto, sans-serif !important;
  color: #141414;
  font-size: 14px;
  font-weight: 500;
}

.Fee p {
  color: #000;
  font-weight: 600;
  font-family: Roboto, sans-serif !important;
}

.summary-heading p {
  font-size: 18px;
  font-weight: 600;
}

label.currency-label {
  margin: 0px;
  position: relative;
  color: #000;
  top: 10px;
  font-weight: 500;
}

label#continue-step2 {
  margin: 10px 115px;
}

.btn.btn-default.btn-success.btn-lg {

  background: linear-gradient(359deg, rgb(96 14 157) -60%, rgb(107 14 175) 69%, rgb(107 14 175) 100%);
  /* background: linear-gradient(359deg, rgb(42, 10, 67) -60%, rgb(227, 133, 218) 69%, rgb(227, 133, 218) 100%); */
  border: 0px;
  margin: 20px 10px;
}

b,
strong {
  font-weight: bolder;
  color: #000;
}

.btn.btn-default.btn-primary.btn-lg {
  background-image: linear-gradient(180deg, #ffffff -60%, #623a80 69%, #461166 100%);
  border: 0px;
  margin: 20px 10px;

}

label.label-card {
  margin: 0px;
  color: #fff;
  position: relative;
  top: 15px;
}

article {
  background: transparent;
  height: auto;
  display: none;
}

article.on {
  display: block;
}

.form-control.input_field {
  margin-bottom: 0;
}

/* =============================================Header============================================= */

.card .card-block {
  padding: 20px;
}

.card .card-block table tr {
  padding-bottom: 20px;
}

.card .card-block .sub-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
}

.card .card-block .dropdown-menu {
  top: 38px;
}

.card .card-block p {
  line-height: 1.4;
}

.card .card-block a.dropdown-item {
  margin-bottom: 0;
  font-size: 14px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.card .card-block a.dropdown-item:active,
.card .card-block a.dropdown-item .active {
  background-color: #448aff;
}

.card .card-block.remove-label i {
  margin: 0;
  padding: 0;
}

.card .card-block.button-list span.badge {
  margin-left: 5px;
}

.card .card-block .dropdown-menu {
  background-color: #fff;
  padding: 0;
}

.card .card-block .dropdown-menu .dropdown-divider {
  background-color: #ddd;
  margin: 3px 0;
}

.card .card-block .dropdown-menu>a {
  padding: 10px 16px;
  line-height: 1.429;
}

.card .card-block .dropdown-menu>li>a:focus,
.card .card-block .dropdown-menu>li>a:hover {
  background-color: rgba(202, 206, 209, 0.5);
}

.card .card-block .dropdown-menu>li:first-child>a:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.card .card-block .badge-box {
  padding: 10px;
  margin: 12px 0;
}

.card .card-block-big {
  padding: 35px;
}

.card .card-block-small {
  padding: 15px;
}

.card .card-block {
  padding: 20px;
}

.card .card-block table tr {
  padding-bottom: 20px;
}

.card .card-block .sub-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
}

.card .card-block .dropdown-menu {
  top: 38px;
}

.card .card-block p {
  line-height: 1.4;
}

.card .card-block a.dropdown-item {
  margin-bottom: 0;
  font-size: 14px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.card .card-block a.dropdown-item:active,
.card .card-block a.dropdown-item .active {
  background-color: #448aff;
}

.card .card-block.remove-label i {
  margin: 0;
  padding: 0;
}

.card .card-block.button-list span.badge {
  margin-left: 5px;
}

.card .card-block .dropdown-menu {
  background-color: #fff;
  padding: 0;
}

.card .card-block .dropdown-menu .dropdown-divider {
  background-color: #ddd;
  margin: 3px 0;
}

.card .card-block .dropdown-menu>a {
  padding: 10px 16px;
  line-height: 1.429;
}

.card .card-block .dropdown-menu>li>a:focus,
.card .card-block .dropdown-menu>li>a:hover {
  background-color: rgba(202, 206, 209, 0.5);
}

.card .card-block .dropdown-menu>li:first-child>a:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.card .card-block .badge-box {
  padding: 10px;
  margin: 12px 0;
}

.card .card-block-big {
  padding: 35px;
}

.card .card-block-small {
  padding: 15px;
}

.Countdown-col-element {
  text-align: center;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 9px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.Countdown-col {
  display: inline-block;
}

/* .Countdown {
  max-width: 274px;
  padding-bottom: 7px;
  margin: 0 auto;
  padding-right: -10px;
  color: #816e4e;
  border-bottom: 1px solid #816e4e;
} */


.Countdown {
  /* max-width: 274px; */
  padding-bottom: 8px;
  margin: 0 auto;
  padding-right: -10px;
  color: #816e4e;
  /* border-bottom: 1px solid #816e4e; */
}

.Countdown-col-element strong {
  border-radius: 3px;
  background: rgba(125, 90, 144, 0.5);
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  padding: 15px 20px;
  /* width: 90px; */
}

.Countdown-col-element span {
  font-size: 14px;
  color: #fff;
  padding-top: 8px;
  font-weight: bold;
}




.containercurrency {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  margin-right: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containercurrency input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

div#buy-tokenss {
  border: 0;
  margin-bottom: 53px;
}

div#referral_earn {
  border: 0px !important;
}

/* On mouse-over, add a grey background color */
.containercurrency:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containercurrency input:checked~.checkmark {
  background-color: #623474;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containercurrency input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containercurrency .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containercurrency .checkmark:after {
  left: 9px;
  top: 9px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* .checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark1 {
  position: absolute;
  top: 47px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.containercurrency:hover input ~ .checkmark1 {
  background-color: #ccc;
} */


* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* h1 {
    text-align: center;
  } */

.accordion {
  font-size: 17px;
  margin: 0 20px;
}

.accordion__button {
  border: 0;
  margin: 10px 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
}

.accordion__button span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
  font-size: 15px;
}

.accordion__button:focus {
  outline: none;
}

div#as-react-datatable-table-body {
  margin-top: 8px !important;
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  transition: all .5s;
  height: 0;
  margin: 0;
  margin-top: -10px;
  border: 1px solid #f1f1f1;
  border-top: 0;
}

.accordion__content.active {
  height: auto;
  opacity: 1;
  padding: 20px;
}

.form-control:focus {
  background-color: unset;
}

/* dashboard css 2 */
.height-auto {
  height: auto !important;
}

.card-token {
  /* background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%); */
  color: #fff;
}

.token-balance:not(:last-child) {
  margin-bottom: 25px;
}

.token-balance-icon {
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  bottom: 15px;
}

.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.1em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.card-token .card-sub-title {
  color: #74fffa;
  margin-bottom: 4px;
}

.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.1em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.token-balance .lead {
  color: #fff;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1;
  letter-spacing: -0.02em;
}

.token-balance .lead span {
  font-weight: 700;
  font-size: .6em;
  letter-spacing: 0.04em;
  margin: 4px;

}

.card-innr {
  padding: 0px 15px;
}

.token-balance-with-icon {
  display: flex;
  align-items: center;
}

.token-balance-list {
  display: flex;
  list-style: none;

}

span.USD {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.card-token .token-balance-list li {
  min-width: 33.33%;
}

.token-balance-s2 .lead {
  font-size: 1.2em;
}

.token-balance .lead {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  letter-spacing: -0.02em;
}

.token-balance-s2 .lead span,
.token-balance-s2 .sub {
  display: block;
  line-height: .9;
  font-size: 11px;
}

.card-token .card-sub-title {
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
}

/* .card-full-height {
  height: calc(100% - 30px);
} */
.height-100 {
  height: 100%;
}

.token-info {
  padding: 28px 0;
}

.token-info {
  /* padding: 22px 0; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.token-info-icon {
  width: 46px;
  height: auto;
  margin-top: 2px;
}

.gaps-2x {
  height: 20px;
}

.token-info-head {
  margin-bottom: 0;
}

/* .text-light {
  color: #758698 !important;
} */
.token-info.bdr-tl {
  border-left: 1px solid #e6effb;
  border-top: 0;
}

.token-info-list {
  padding-bottom: 20px;
}

.token-info-head {
  margin-bottom: 0;
  font-size: 30px;
}

.token-info-sub {
  font-size: 16px;
  color: #000;
  padding-top: 0px;
}

.token-info-list {
  list-style: none;
  color: #000;
}

.token-info-list li span {
  color: #7114b1;
  font-weight: 600;
  min-width: 110px;
  display: inline-block;
}

.token-balance-icon img {
  width: 40px;
  line-height: 50px;
}

.newdashboard .token-statistics {
  min-height: 222px;
  background: #ffffff !important;
  border-radius: 30px;
  /* border: 1px solid #70707029; */
}

.card-head.has-aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  color: #000;
  font-size: 25px;
  padding-bottom: 36px;
  padding-top: 43px;
  font-weight: 600;
  margin: 0;
}

.Buy_Token ol {
  font-size: 14px;
  padding: 22px;
  line-height: 2;
  text-align: justify;
  padding: 0px 40px;
}

a.link.ucap {
  font-size: 15px;
  color: #000;
}

.badge-outline-white {
  background: transparent;
  border: 1px solid #6e11b0 !important;
  padding: 9px;
  font-size: 13px;
}

.card-opt {
  position: relative;
}

.token-transaction table {
  margin-top: -8px;
  margin-bottom: -5px;
}

.table thead th {
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  font-size: 12px;

  font-weight: 700;
  color: #fff;
}

.data-state-pending:after {
  content: '\e6c5';
  color: #ffc100;
  border-color: #ffc100;
}

.data-state:after {
  display: block;
  font-family: 'themify';
  color: #2c80ff;
  height: 100%;
  width: 100%;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid;
}

.token-transaction table tr th {
  color: #6f12b0;
}

.token-transaction table tr td {
  color: #000;
}

p.card-title-text {
  font-size: 15px;
  padding-top: 12px;
  color: #000;
  font-weight: 600;
  padding-bottom: 11px;
}

.token-calc {
  display: flex;
  padding-bottom: 10px;
}

.token-pay-amount {
  position: relative;
  align-self: center;
  width: 171px;
}

.token-pay-amount .input-with-hint {
  padding-right: 75px;
}

.token-pay-amount .input-bordered {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  width: 100%;
  padding: 10px 78px 10px 16px;
  line-height: 20px;
  font-size: 16px;
  background: transparent;
  color: #000;
  transition: all .4s;
}

.token-pay-currency {
  position: absolute;
  right: 0;
  top: 0px;
  transform: translate(0);
  z-index: 1;
  padding: 0 10px;
  border-left: 1px solid #d2dde9;
}


.toggle-caret {
  position: relative;
  padding-right: 16px;
}

.toggle-tigger {
  cursor: pointer;
}

.link {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: all .2s;
}

.token-received {
  display: inline-flex;
  align-items: center;
}

.token-symbol {
  font-size: 12px;
}

.token-received {
  color: #000;
  padding-left: 20px;
}

h5.token-amount {
  padding: 0;
}

.token-received-amount {
  padding-left: 15px;
}

.note p,
.note .note-text {
  font-size: 12px !important;
  color: #000;
}

.toggle-caret:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  height: 0;
  width: 0;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-top: 5px solid #b1becc;
}

.nav-tabs-dashboard {
  margin-top: 6%;
}

.nav-tabs-dashboard .nav-tabs .nav-item.show .nav-link,
.nav-tabs-dashboard .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none !important;
  border-bottom: 1px solid #a043c4 !important;
}

.nav-tabs-dashboard .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  border: none !important;
  border-bottom: 1px solid #a043c4 !important;
}

.nav-tabs-dashboard .nav-tabs .nav-link {
  border: 1px solid transparent;
  color: #fff;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs-dashboard .nav>li {
  position: relative;
  display: block;
  width: 9%;
}

/* .newdashboard .bg-shadow {
  background: #fff!important;
  box-shadow: 0px 2px 10px #dbdbdb!important;
} */
.newdashboard {
  font-family: Roboto, sans-serif !important;
}

.containercurrency {
  display: inline-block !important;
}

.containercurrency .checkmark {
  top: 0;
}

/* -==================== */
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/*  */

svg.tokenChart {
  fill: #fff;
  width: 100% !important;
}

g.tokenChart-legend {
  transform: translate(10px, 110px);
}

/* ====================================== */
/* @import 'https://code.highcharts.com/css/highcharts.css';  */
.highcharts-background {
  fill: transparent !important;
}

.highcharts-pie-series .highcharts-point {
  stroke: #EDE;
  stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
  stroke: silver;
  stroke-dasharray: 2, 2;
  stroke-width: 2px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 600px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #EBEBEB;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.faq .accordion .accordion-item.active .title {
  /* background-color: #eee; */
  background: linear-gradient(289deg, #3d2f5d -60%, #533469 69%, #533469 100%);
}

.faq .accordion .accordion-item .title {
  background: linear-gradient(289deg, #3d2f5d -60%, #533469 69%, #533469 100%);
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}

.faq .accordion .accordion-item.active .panel {
  max-height: 500px;
  padding: 20px;
  transition: max-height 0.25s ease-in;
}

.faq .accordion .accordion-item .title:after {
  content: '\2795';
  font-size: 13px;
  color: #fff;
  float: right;
  margin-left: 5px;
}

.faq .accordion .accordion-item.active .title:after {
  color: #fff;
}

.driven {
  top: 21px;
  left: 50%;
  right: 50%;
  width: 100%;
  display: block;
  position: relative;
  margin: 0 auto;
}

.mob-content b {
  color: #fff;
}

.btn-outline-apple {
  background: transparent !important;
  border-color: rgb(76, 18, 103) !important;
  border: 1px solid;
  min-height: 64px;
    padding: 7px 0px;
    line-height: 25px;
  color: #000 !important;
}

.cursorshow {
  cursor: not-allowed !important
}

/* =============================== */
.newdashboard .card-title {
  color: #4A1264;
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 20px;
  padding-top: 0px;
  margin: 0;
  text-align: center;
}

.newdashboard .card-innr {
  /* padding: 16px 20px; */
  /* border-color: transparent !important; */
}

.token-balance.token-balance-with-icon {
  border-bottom: 1px solid #70707029 !important;
  padding-bottom: 10px;
}

.newdashboard .btn-primary {
  color: #fff;
}

span.leads {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 0;
  letter-spacing: -0.02em;
  position: relative;
  bottom: 12px;
}

h2.card-titles {
  color: #4A1264;
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: 900;
}

/* thank you page css */
.thankyoupage {
  margin: 186px auto;
}

.thankyoupage p {
  color: #fff;
  line-height: 38px;
  margin-top: 18px;
  font-size: 20px;
}

.social-menu ul {
  list-style: none;
}

.social-menu ul li {
  display: inline-block;
  color: #fff;
}

.social-menu a {
  color: #fff;
}

.login100-form-btn-thanku {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 6px;
  font-weight: 400;
  width: 100%;
  border: 0px;
  height: 34px;
  margin-left: 0;
  border-radius: 10px;
  background-image: linear-gradient(359deg, rgb(42, 10, 67) -60%, rgb(227, 133, 218) 69%, rgb(227, 133, 218) 100%);
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s;
}

.login100-form-btn-thanku a {
  font-weight: 700;
  font-size: 13px;
}

h5.referral_link {
  font-size: 20px;
  color: #4A1264;
  padding: 0px;
  margin-bottom: 44px;
}

rea.bg-white.card.bg-shadow {
  margin-top: 20px;
}

span.referral_link_span {
  width: 100%;
  color: #000 !important;
  font-size: 13px;
  background: #80808036;
  padding: 10px 4px;
  border-radius: 5px;
  margin: 10px 0px;
}

div#but-token-input {
  padding: 0px 20px;
}

div#but-token-input input {
  font-size: 16px;
  color: #707070;
  font-weight: 500;
  width: 100%;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  z-index: 0
}

div#but-token-input span {
  font-size: 16px;
  width: 75px;
  text-align: center;
  display: grid;
  background: transparent;
  border-left: 1px solid #707070 !important;
  border: 0px;
  z-index: 9;
  margin: 0px 0px;
  border-radius: 0px;
  color: #707070;
  font-weight: 500;
  position: absolute;
  right: 25px;
  top: 10px;
}

span.yuse_amount {
  font-weight: 200;
}

h5.usd_mini {
  padding: 10px;
}

.token-information.bg-white.bg-shadow.card-full-height {
  border-bottom: 1px solid #70707029;
}

h2.Currency-title {
  padding: 0px 10px;
  font-size: 14px;
  text-align: center;
}

input#flexCheckDefault {
  opacity: 1;
  display: block;
  left: 20px;
  top: 3px;
  border-radius: 30px !important;
}

input#checkboxNoLabel {
  display: block;
  opacity: 1;
  top: 3px;
  left: 20px;
}

label.form-check-label {
  color: purple;
  font-size: 14px;
}

label.form-check-label.label_grey {
  color: #707070;
  font-size: 14px;
}

p.fees_prices {
  width: 100%;
  padding: 15px;
  font-weight: 700;
  color: #707070;
}

button.connect_wallet_btn.btn.btn-primary {
  margin: 25px auto;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px;
  text-transform: capitalize;
  /* padding: 5px 20px; */
  padding: 1px 0px;
  background: #4c1368 !important;
}

h4.referral_link.card-title {
  padding: 0px;
}

button.connect_wallet_btn.btn.btn-primary:hover {
  border-color: #7014b1 !important;
  background: #7014b1 !important;

}

.Buy_Token ol li {
  line-height: 1.3;
  margin: 10px 0px;
  color: #444;
  font-weight: 500;
}

.dashboard_buytoken_card {
  height: 500px;

  background: #fff;
  border: 1px solid #7070702b;
  box-shadow: 0px 2px 10px #7070702b;
  border-radius: 30px;
  padding: 20px 10px;
}

a.btn.btn-primary {
  margin: 52px 45px;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px 20px;
  text-transform: capitalize;
  background: #4c1368 !important;
}

i.fa {
  margin: 0px 10px;
}

i.fa,
i.far,
i.fab {
  margin: 0 10px;
}

.card-innr .card-title {
  text-align: left;
}

i.fal {
  margin: 0px 10px;
}

.header-menu-tabs aside a {
  text-decoration: none;
}

aside.mobile_view {
  display: none;
}

div#transaction_table {
  margin-top: 20px;
}

button.btn-primary.btn-lg.pull-right {
  width: 80px !important;
}

.alert_validation {
  color: red;
}

.d-sm-flex.justify-content-between.align-items-center {
  position: relative;
  /* left: 53px!important; */
  top: 19px;
}

.checkmark-staking1 {
  position: absolute;
  top: 0px;
  left: -18px;
  height: 20px !important;
  width: 20px !important;
  background-color: #ccc0 !important;
  border-radius: 50%;
  border: 1px solid #ccc !important;
}

.checkmark-staking2 {
  position: absolute;
  top: 2px;
  left: -42px;
  height: 20px !important;
  width: 20px !important;
  background-color: #ccc0 !important;
  border-radius: 50%;
  border: 1px solid #ccc !important;
}

h4#recomended {
  position: absolute;
  margin-top: 76px;
  font-size: 15px;

}

h4#recomended1st {

  font-size: 15px;

}

h4#redemption {
  position: absolute;
  margin-top: 135px !important;
  font-size: 15px;
}

div#as-react-datatable-container {
  margin-top: 49px !important;
}



h6.wallet_text-add {
  /* margin-top: -40px!important; */
  font-family: 'Poppins' sans-serif !important;
  font-size: 18px;
  text-align: right;
  color: #1f0e9c;
  font-weight: 600;
}

ul.dropdown-menu.show {
  border-bottom: 1px solid #555;
  background: #ffffff00;
  position: absolute;
  will-change: transform;
  top: 5px !important;
  right: 10px !important;
  /* left: -10px!important; */
  transform: translate3d(0px, 22px, 0px);
  border: 0px !important;
}

a#connect_btn_top {
  margin: 10px 10px;
}

a#copy_wallet {
  margin: 0px 10px;
  position: relative;
  right: 0px;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #f5f5f5;
}

button#connect_disconnet_button {
  float: right;
  position: relative;
  bottom: 25px;
  left: 40px;
  width: 40%;
}

button#dropdown-basic {
  bottom: 25px;
}

.banner-slider .slick-prev,
.banner-slider .slick-next {
  display: none !important;
}

.staking_faq .bg-pink {
  background: linear-gradient(289deg, #761b7c -60%, #d35bfb 69%, #a266cd 100%);
  color: #fff;
}

.staking_faq .accordion {
  font-size: 17px;
  margin: 0 0px;
}

.staking_faq .faq .collapse.in {

  border-radius: 4px;
  background-image: linear-gradient(289deg, #3d2f5d00 -60%, #53346900 69%, #53346900 100%) !important;
  color: #000;
}

.staking_faq .font-weight-light {
  font-weight: 600 !important;
  color: #000 !important;
}

.mt-16 {
  margin-top: 16px;
}

#inProgressPopup .close {
  color: #fff;
  opacity: 1;
  font-weight: 100 !important;
  font-size: 26px;
  border: none !important;
}

div#inProgressPopup .modal-body {
  text-align: center;
  font-weight: 500 !important;
  padding-bottom: 38px;
}

div#inProgressPopup .modal-body h5 {
  color: #fff;
  padding: 6px 10px 6px 10px;
}

div#inProgressPopup .modal-content {
  border-radius: 20px !important;
}

.btn.buy-token-btn {
  border-radius: 5px;
  font-size: 12px;
  padding: 2px 20px;
  text-transform: capitalize;
  margin-top: 22px;
  background: #4c1368 !important;
}

.contract {
  background: linear-gradient(289deg, #3d2f5d -60%, #533469 69%, #533469 100%) !important;
  padding: 0px 15px 0px 15px;
  border-radius: 10px;
  color: #310961;
  word-break: break-all;
  text-align: left;
  font-weight: 800;
}

.contract .btn-primary {
  background: #4c1368 !important;
  border-radius: 11px;
  padding: 4px 2px;
}

.otp {
  height: 30px;
  background: transparent;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  color: #fff !important;
}

.send label {
  font-weight: 600 !important;
  color: #fff !important;
  font-size: 16px;
}

.send .btn-primary {
  background: #4B1267 !important;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 5px 24px;
  font-weight: 800 !important;
}

.send .btn-primary:hover {
  background: #4B1267 !important;
}

.as-react-table {
  overflow-x: auto;
}

.send .close {
  color: #fff;
  opacity: 1;
  font-weight: 100 !important;
  border: none !important;
  font-size: 26px;
  border: none !important;
}

.send .fa-spinner {
  font-size: 18px;
}

/* .marquee-img {
  height: 100px;
  width: 100px;
  margin-left: 40px;
} */


.marquee-img {
  height: auto;
  width: 150px;
  margin-left: 40px;
  object-fit: contain;
  transition: all 0.7s ease;

}

img.marquee-img.one {
  width: 60px;
  height: 60px;
}

img.marquee-img.two {
  width: 350px;
  height: auto;
}

.marquee-container {
  width: 100%;
  height: 70px;
  /* background: white; */
  /* background: #0b0c22; */
  background: linear-gradient(289deg, #3d2f5d -60%, #533469 69%, #533469 100%);

}

.marquee a img {
  filter: grayscale(1);
}

img.marquee-img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  filter: none;
}

.marquee {
  /* animation: none !important; */
  z-index: 9999 !important;
}

.text-center.boxheight {
  height: 50px;
}

.overlay::before,
.overlay::after {
  background: none !important;
  /* background: linear-gradient(to right, var(--gradient-color)); */
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2;
}

img.marquee-img.three {
  width: 67%;
}

img.nasdaq-feature {
  width: 95%;
}

.bg_color {
  background: linear-gradient(289deg, #3d2f5d -60%, #533469 69%, #533469);
}

img.img-f {
  filter: grayscale(1);
  width: 40%;
}

.partner-logo-seen img:hover {
-webkit-filter: brightness(100%);
transition: all 0.7s ease;
  transform: scale(1.1);
}
.partner-logo-seen img {
  filter: grayscale(1);
}
.boxpartner{
  padding: 30px 0px;
}
.btn-google-play {
  min-height: 64px;
    padding: 7px 0px;
    line-height: 25px;
}
